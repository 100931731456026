import makeStore from "../../utils/make-store";
import reducer from "./reducer";
import GameScenario from "./scenario";

const CONFIG_MODULE_LS_KEY = 'dds-game'

export type GameModuleState = {
    activeStepId: string,
    state: {[key: string]: boolean}
}

export const initGameModuleState: GameModuleState = {
    activeStepId: GameScenario.startStep,
    state: {}
}

export enum GAME_MODULE_ACTION {
    GO_TO_STEP,
    RESTART,
    UPDATE_GAME_STATE
}

export type GameModuleAction = {
    type: GAME_MODULE_ACTION,
    payload?: any
}

const [
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
] = makeStore(reducer, initGameModuleState, CONFIG_MODULE_LS_KEY)


export {
    GameModuleProvider,
    useGameModuleDispatch,
    useGameModuleState
}
