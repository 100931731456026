import { GameData, GameStepType } from "./types";

// const DEMO_VIMEO_ID1 = 451526306;
// const DEMO_VIMEO_ID2 = 451525251;
// const DEMO_VIMEO_ID3 = 451527548;
// const DEMO_VIMEO_ID4 = 451526062;

const GameScenario: GameData = {
    startStep: '2',
    steps: [
        // {
        //     id: '1',
        //     type: GameStepType.video,
        //     source: 'vimeo',
        //     vimeoId: DEMO_VIMEO_ID1,
        //     goOnVideoEnd: ['2'],
        //     events: [
        //         {
        //             from: 3,
        //             to: Infinity,
        //             text: 'Dom Śmiałych Strachów'
        //         }
        //     ],
        // },
        {
            id: '2',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 785033519,
            goOnVideoEnd: ['3'],
            events: []
        },
        {
            id: '3',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783918440,
            goOnVideoEnd: ['4'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Światło w opuszczonym budynku? Ciekawe…'
                }
            ]
        },
        {
            id: '4',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783918515,
            events: [
                {
                    from: 0,
                    to: Infinity,
                    text: 'Jeszcze przed chwilą nikogo tutaj nie było. Kim jest ta tajemnicza postać?'
                },
                {
                    from: 3,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Poczekaj i zobacz, co się wydarzy.',
                            target: '5',
                            updateGameState: { visited5: true }
                        },
                        {
                            text: 'Spróbuj się przywitać.',
                            target: '6',
                            updateGameState: { visited6: true }
                        },
                        {
                            text: 'Zwróć uwagę na tapetę.',
                            target: '7',
                            updateGameState: { visited7: true }
                        }
                    ]
                }
            ]
        },
        {
            id: '5',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 785026198,
            goOnVideoEnd: ['8'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Czekasz i bacznie obserwujesz, co wydarzy się dalej. Postać wygląda, jakby na coś czekała… '
                }
            ]
        },
        {
            id: '6',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783919220,
            goOnVideoEnd: ['8'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Podchodzisz bliżej, by się przywitać, ale postać nie reaguje. Zachowuje się, jakby Cię nie widziała ani nie słyszała. '
                }
            ]
        },
        {
            id: '7',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783917480,
            events: [
                {
                    from: 0,
                    to: 20,
                    text: 'Dopiero gdy zwracasz uwagę na elegancką tapetę, którą pokryta jest duża część pokoju, postać ożywia się i zaczyna mówić.'
                },
                {
                    from: 20,
                    to: Infinity,
                    text: 'Pomóż tapeciarce dokończyć zadanie, licząc wszystkie świecące kwiaty. Dzięki temu będzie mogła odejść spokojnie z tego miejsca.',
                    actions: [
                        {
                            text: 'Spójrz w lewo.',
                            target: '9',
                            updateGameState: {
                                visited9: true
                            }
                        },
                        {
                            text: 'Spójrz w prawo.',
                            target: '10',
                            updateGameState: {
                                visited10: true
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: '8',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783918515,
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Może spróbuj zrobić coś innego?'
                },
                {
                    from: 6,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Poczekaj i zobacz, co się wydarzy.',
                            target: '5',
                            visible: [{ visited5: false }],
                            updateGameState: { visited5: true }

                        },
                        {
                            text: 'Spróbuj się przywitać.',
                            target: '6',
                            visible: [{ visited6: false }],
                            updateGameState: { visited6: true }
                        },
                        {
                            text: 'Zwróć uwagę na tapetę.',
                            target: '7',
                            visible: [{ visited7: false }],
                            updateGameState: { visited7: true }
                        }
                    ]
                }
            ]
        },
        {
            id: '9',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783973200,
            goOnVideoEnd: [
                // ['12', [{ visited9: true, visited10: true }]],
                '11'
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Spróbuj policzyć wszystkie świecące kwiaty!',
                }
            ]
        },
        {
            id: '10',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783973101,
            goOnVideoEnd: [
                // ['12', [{ visited9: true, visited10: true }]],
                '11'
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Spróbuj policzyć wszystkie świecące kwiaty!',
                }
            ]
        },
        {
            id: '11',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783919220,
            events: [
                {
                    from: 0,
                    to: Infinity,
                    text: 'Obejrzyj resztę pokoju. ',
                    visible: [{ visited9: false }, { visited10: false }]
                },
                {
                    from: 0,
                    to: Infinity,
                    visible: [{ visited9: false }, { visited10: false }],
                    actions: [
                        {
                            text: 'Spójrz w lewo.',
                            target: '9',
                            visible: [{ visited9: false }],
                            updateGameState: {
                                visited9: true
                            }

                        },
                        {
                            text: 'Spójrz w prawo.',
                            target: '10',
                            visible: [{ visited10: false }],
                            updateGameState: {
                                visited10: true
                            }

                        }
                    ]
                },
                {
                    from: 3,
                    to: Infinity,
                    text: 'Ile jest świecących kwiatów?',
                    visible: [{ visited9: true, visited10: true }]
                },
                {
                    from: 6,
                    to: Infinity,
                    visible: [{ visited9: true, visited10: true }],
                    actions: [
                        {
                            text: 'Sześć.',
                            target: ['12'],
                        },
                        {
                            text: 'Siedem.',
                            onClickMessage: 'To nie jest dobra odpowiedź'
                        },
                        {
                            text: 'Dziewięć',
                            onClickMessage: 'To nie jest dobra odpowiedź'
                        }
                    ]
                }
            ]
        },
        {
            id: '12',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783919254,
            events: [
                {
                    from: 3,
                    to: 20,
                    text: 'Wchodzisz do przestrzeni, którą wypełniają  elementy konstrukcyjne zamku i dziwne dźwięki. Próbujesz przejść dalej, ale jakaś magnetyczna siła przyciąga cię w stronę tajemniczego wejścia.'
                },
                {
                    from: 20,
                    to: Infinity,
                    text: 'Musisz rozwiązać zagadkę tego miejsca.',
                    actions: [
                        {
                            text: 'Obejrzyj dokładnie zamek.',
                            target: '13'
                        },
                        {
                            text: 'Zapytaj: Czy jest tu kto?',
                            target: '14'
                        }
                    ]
                }
            ]
        },
        {
            id: '13',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783919971,
            goOnVideoEnd: ['14'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Zamek pokrywają pajęczyny i gruba warstwa kurzu. Na froncie widzisz masywny portret damy zasłonięty tkaniną. Może kiedyś była panią tego majątku? Ale skąd, u licha, wziął się tu zamek? '
                }
            ]
        },
        {
            id: '14',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920080,
            goOnVideoEnd: ['15'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Skrzeczenie kruków się nasila. Zamek sprawia wrażenie, jakby powoli ożywał. Nagle pojawia się przed tobą osoba w dworskim stroju. Kto to może być? '
                }
            ]
        },
        {
            id: '15',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783917615,
            goOnVideoEnd: ['16'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Kolejny duch w opałach. Pomóż mu, wybierając właściwy element ubioru do zwrotu.'
                }
            ]
        },
        {
            id: '16',
            type: GameStepType.custom,
            pageId: 'page-16'
        },
        {
            id: '17',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920305,
            goOnVideoEnd: ['19'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Hrabia kiwa z uznaniem głową i razem z upiornymi krukami opuszcza swój zamek na zawsze.'
                }
            ]
        },
        {
            id: '18',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920332,
            goOnVideoEnd: ['16'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'To nie tego elementu stroju szuka hrabina. Duch znika szybko za murami zamku. Spróbuj jeszcze raz.'
                }
            ]
        },
        {
            id: '19',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920374,
            goOnVideoEnd: ['20'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Jaką tajemnicę kryje kolejny pokój? Czy to więzienie? Kazamaty? Nie… To sejf bankowy pełen skarbów!'
                }
            ]

        },
        {
            id: '20',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920435,
            events: [
                {
                    from: 3,
                    to: 15,
                    text: 'Jak tam się dostać? Krata broni dostępu do ryglowanych drzwi, ale jakby tak lekko podważyć, popchnąć skobelek…'
                },
                {
                    from: 15,
                    to: Infinity,
                    text: 'Otwiera się! Za drzwiami dostrzegasz jednak jakiś ruch.',
                    actions: [
                        {
                            text: 'Zapukaj.',
                            target: '21'
                        },
                        {
                            text: 'Zobacz, co się stanie.',
                            target: '22'
                        }
                    ]
                }
            ]
        },
        {
            id: '21',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920603,
            goOnVideoEnd: ['23'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Zza krat wyłania się jakaś postać. Czy to kolejna zjawa? Przyglądasz się dokładnie'
                }
            ]
        },
        {
            id: '22',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920603,
            goOnVideoEnd: ['23'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Zza krat wyłania się jakaś postać. Czy to kolejna zjawa?'
                }
            ]
        },
        {
            id: '23',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783920708,
            goOnVideoEnd: ['24'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Więzień zaczyna do ciebie mówić, ale nic z tego nie rozumiesz. Wskazuje palcem na skrzynkę z kluczami. Może uda ci się odnaleźć właściwy klucz i uwolnić ducha?'
                }
            ]
        },
        {
            id: '24',
            type: GameStepType.custom,
            pageId: 'page-24'
        },
        {
            id: '25',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783921029,
            goOnVideoEnd: ['26'],
            events: [
                {
                    from: 3,
                    to: 30,
                    text: 'Idąc korytarzem, docierasz do schodów. Po ścianach przebiegają dziwne światła i cienie, a z góry słychać jakieś odgłosy.'
                },
                {
                    from: 30,
                    to: Infinity,
                    text: 'Czy to muzyka? Czy czyjś głos? Kto to wszystko wymalował i zostawił niedokończone? Wygląda, jakby warsztaty malarskie coś przerwało…',
                }
            ]
        },
        {
            id: '26',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783921153,
            events: [
                {
                    from: 3,
                    to: 20,
                    text: 'Poszukaj jakichś śladów. Co tu się stało? Gdzie są duchy artystów?'
                },
                {
                    from: 20,
                    to: Infinity,
                    text: 'Rozejrzyj się i spróbuj policzyć, ile osób uczestniczyło w warsztatach malarskich.',
                    actions: [
                        {
                            text: 'W prawo.',
                            target: '27',
                            updateGameState: { visited27: true }
                        },
                        {
                            text: 'W lewo.',
                            target: '28',
                            updateGameState: { visited28: true }
                        },
                        {
                            text: 'Na podłodze.',
                            target: '29',
                            updateGameState: { visited29: true }
                        }
                    ]
                }
            ]
        },
        {
            id: '27',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783921280,
            goOnVideoEnd: ['30'],
            events: [
                {
                    from: 0,
                    to: Infinity,
                    text: 'Te portrety to nie są zwykłe malunki. Są w nich zaklęte duchy ofiar. Szepczą między sobą, szukają ucieczki.'
                }
            ]
        },
        {
            id: '28',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783921363,
            goOnVideoEnd: ['30'],
            events: [
                {
                    from: 0,
                    to: Infinity,
                    text: 'Ciche szepty ze ścian wskazują na to, że portrety na ścianach to uwięzione duchy uczestników zajęć plastycznych. Nie dotykać!'
                }
            ]
        },
        {
            id: '29',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 785026651,
            goOnVideoEnd: ['30'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Czy te kształty to ślady kolejnych ofiar nieudanych warsztatów malarskich?'
                }
            ]
        },
        {
            id: '30',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783921153,
            events: [
                {
                    from: 0,
                    to: Infinity,
                    text: 'Rozejrzyj się po innej części pomieszczenia. Spróbuj policzyć wszystkie duchy osób, które uczestniczyły w zajęciach.',
                    visible: [
                        { visited27: false },
                        { visited28: false },
                        { visited29: false }
                    ]
                },
                {
                    from: 0,
                    to: Infinity,
                    visible: [
                        { visited27: false },
                        { visited28: false },
                        { visited29: false }
                    ],
                    actions: [
                        {
                            text: 'W prawo.',
                            target: '27',
                            updateGameState: {
                                visited27: true
                            },
                            visible: [
                                {
                                    visited27: false
                                }
                            ]
                        },
                        {
                            text: 'W lewo.',
                            target: '28',
                            updateGameState: {
                                visited28: true
                            },
                            visible: [
                                {
                                    visited28: false
                                }
                            ]
                        },
                        {
                            text: 'Na podłodze.',
                            target: '29',
                            updateGameState: {
                                visited29: true
                            },
                            visible: [
                                {
                                    visited29: false
                                }
                            ]
                        }
                    ]
                },
                {
                    from: 3,
                    to: Infinity,
                    text: 'Ile osób uczestniczyło w warsztatach?',
                    visible: [
                        {
                            visited27: true,
                            visited28: true,
                            visited29: true
                        }
                    ],
                    actions: [
                        {
                            text: 'Cztery.',
                            onClickMessage: 'To nie jest dobra odpowiedź'
                        },
                        {
                            text: 'Sześć.',
                            onClickMessage: 'To nie jest dobra odpowiedź'
                        },
                        {
                            text: 'Dziesięć.',
                            target: '31'
                        }
                    ]
                }
            ]
        },
        {
            id: '31',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940224,
            goOnVideoEnd: ['32'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Idziesz wzdłuż korytarza. Gdzieś w oddali przemyka cień postaci… Może to kolejna zjawa? Próbujesz za nią podążyć.'
                }
            ]
        },
        {
            id: '32',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922571,
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Gdzieś tutaj ukryty jest znak, składający się z litery i cyfry. Postaraj się go odnaleźć i zapamiętać. Będzie ci jeszcze potrzebny!'
                },
                {
                    from: 6,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Sprawdź na podłodze.',
                            target: '33',
                            updateGameState: {
                                visited33: true
                            }
                        },
                        {
                            text: 'Rozejrzyj się po lewej.',
                            target: '34',
                            updateGameState: {
                                visited34: true
                            }
                        },
                        {
                            text: 'Zobacz, co jest we wnęce.',
                            target: '35',
                            updateGameState: {
                                visited35: true
                            }
                        }
                    ]
                }
            ]
        },
        {
            id: '33',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922654,
            goOnVideoEnd: [
                ['36.1', [{ visited33: true, visited34: true, visited35: true }]],
                '36',
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Tutaj chyba nic nie ma…'
                }
            ]
        },
        {
            id: '34',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922708,
            goOnVideoEnd: [
                ['36.1', [{ visited33: true, visited34: true, visited35: true }]],
                '36',
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Czyżby na ścianie było coś napisane…??'
                }
            ]
        },
        {
            id: '35',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922730,
            goOnVideoEnd: [
                ['36.1', [{ visited33: true, visited34: true, visited35: true }]],
                '36',
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Ciemna postać we wnęce okazuje się plamą sadzy... Tylko czy na pewno?'
                }
            ]
        },
        {
            id: '36',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922571,
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Przeszukaj pozostałą część pomieszczenia.'
                },
                {
                    from: 6,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Sprawdź na podłodze.',
                            target: '33',
                            updateGameState: {
                                visited33: true
                            },
                            visible: [{ visited33: false }]
                        },
                        {
                            text: 'Rozejrzyj się po lewej.',
                            target: '34',
                            updateGameState: {
                                visited34: true
                            },
                            visible: [{ visited34: false }]
                        },
                        {
                            text: 'Zobacz, co jest we wnęce.',
                            target: '35',
                            updateGameState: {
                                visited35: true
                            },
                            visible: [{ visited35: false }]
                        }
                    ]
                }
            ]
        },
        {
            id: '36.1',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922761,
            goOnVideoEnd: ['37'],
            events: [
                {
                    from: 3,
                    to: 10,
                    text: 'Już masz wychodzić z toalety, gdy słyszysz za sobą jakieś odgłosy. Oglądasz się i… '
                },
                {
                    from: 10,
                    to: Infinity,
                    text: 'To kolejny duch! Odwracasz się i szybkim krokiem idziesz w drugą stronę.'
                }
            ]
        },
        {
            id: '37',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922866,
            goOnVideoEnd: ['38'],
            events: [
                {
                    from: 3,
                    to: 40,
                    text: 'Trafiasz do umywalni. Zimny wiatr wpada przez rozbite okno, ale nikogo tutaj nie ma. Światło ciągnie cię do kolejnego pomieszczenia…'
                },
                {
                    from: 40,
                    to: Infinity,
                    text: 'Zaraz… Czy na drzwiach był kolejny znak?'
                }
            ]
        },
        {
            id: '38',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783922985,
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Samotna postać stoi i dudni w przewód wentylacyjny. Wygląda na zajętą… Może lepiej jej nie przeszkadzać?'
                },
                {
                    from: 6,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Zobacz, co się stanie.',
                            target: '39'
                        },
                        {
                            text: 'Przywitaj się.',
                            target: '39'
                        }
                    ]
                }
            ]
        },
        {
            id: '39',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783917708,
            goOnVideoEnd: ['40'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Pomóż duchowi odszyfrować wiadomość.'
                }
            ]
        },
        {
            id: '40',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 785993204,
            events: [
                {
                    from: 15,
                    to: Infinity,
                    text: 'Czas podać zaszyfrowane hasło. Czego potrzebuje krokodyl?',
                    actions: [
                        {
                            text: 'Gwiazdki z nieba.',
                            onClickMessage: 'To nie jest poprawne hasło.'
                        },
                        {
                            text: 'Świętojańskiego chleba.',
                            target: '41'
                        },
                        {
                            text: 'Krokodylego szczęścia.',
                            onClickMessage: 'To nie jest poprawne hasło.'
                        },
                    ]
                }
            ]
        },
        {
            id: '41',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940280,
            goOnVideoEnd: ['42'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Gdy monterka czeka na ostatnie połączenie międzywymiarowe, ty małymi kroczkami przechodzisz do kolejnego pomieszczenia. Zimne światło pada na morze kwiatów i wijących się pnączy.'
                }
            ]
        },
        {
            id: '42',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940338,
            goOnVideoEnd: ['43'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Duch młodej dziewczyny krąży dookoła wózka z kwiatami i odgania piszczałką nieistniejące ptaki. Po chwili cię dostrzega…'
                }
            ]
        },
        {
            id: '43',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783917813,
            goOnVideoEnd: ['44'],
            events: []
        },
        {
            id: '44',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940411,
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Rozejrzyj się po pomieszczeniu i spróbuj wskazać miejsce, w którym ukryty jest bukiet kwiaciarki.'
                },
                {
                    from: 6,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Pod wózkiem drabiniastym.',
                            target: [
                                '45'
                            ],
                            updateGameState: { clicked44_1: true }
                        },
                        {
                            text: 'Obok balustrady.',
                            onClickMessage: 'Poszukaj gdzie indziej.',
                            updateGameState: { clicked44_2: true }
                        },
                        {
                            text: 'Za kaloryferem.',
                            onClickMessage: 'Poszukaj gdzie indziej.',
                            updateGameState: { clicked44_3: true }
                        }
                    ]
                }
            ]
        },
        {
            id: '45',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940477,
            goOnVideoEnd: ['46'],
            events: [
                {
                    from: 0,
                    to: Infinity,
                    text: 'Florentyna była cały czas tuż obok rozwiązania zagadki. Dzięki Tobie może teraz opuścić dom w spokoju.'
                }
            ]
        },
        {
            id: '46',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940535,
            goOnVideoEnd: ['47'],
            events: [
                {
                    from: 3,
                    to: 15,
                    text: 'Po uwolnieniu ducha kwiaciarki kierujesz się w stronę stołówki.'
                },
                {
                    from: 15,
                    to: Infinity,
                    text: 'Czy na lustrze jest jakaś liczba? Może to kolejny znak?'
                }
            ]
        },
        {
            id: '47',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783917881,
            events: [
                {
                    from: 0,
                    to: 6,
                    text: 'Zdecydowanym krokiem wchodzisz do stołówki, w której spotykasz ducha kucharki i jej pomocników.'
                },
                {
                    from: 6,
                    to: Infinity,
                    text: 'Spróbujesz przygotowanego przez nich wywaru?',
                    actions: [
                        {
                            text: 'Tak, chętnie spróbuję.',
                            target: '48'
                        },
                        {
                            text: 'Dziękuję, nie chce mi się pić.',
                            target: '49'
                        }
                    ]
                },

            ]
        },
        {
            id: '48',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940575,
            goOnVideoEnd: ['50'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Duchy milczą gdy ty przełykasz zupę pełną dziwnych składników. Po chwili musisz przyznać, że ten posiłek był słabo przyprawiony.'
                }
            ]
        },
        {
            id: '49',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783940598,
            goOnVideoEnd: ['50'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Duchy milczą i patrzą na Ciebie z frustracją. Czujesz kwaśny zapach i to im chyba musi wystarczyć. Przynajmniej na razie.'
                }
            ]
        },
        {
            id: '50',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783917975,
            goOnVideoEnd: ['51'],
            events: [
                {
                    from: 10,
                    to: Infinity,
                    text: 'Czas na konfrontację z kierownikiem tej placówki. Dopóki on nie odejdzie, to miejsce pozostanie nawiedzone.'
                }
            ]
        },
        {
            id: '51',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783971596,
            events: [
                {
                    from: 3,
                    to: 40,
                    text: 'Dyrektor wita cię w swoim gabinecie i zaczyna opowiadać o swoich pracownikach.'
                },
                {
                    from: 40,
                    to: Infinity,
                    text: 'O którym duchu chciałbyś porozmawiać?',
                    actions: [
                        {
                            text: 'Posłucham o kucharce.',
                            target: '52',
                            updateGameState: {
                                visited52: true
                            }
                        },
                        {
                            text: 'Zapytam o tapeciarkę.',
                            target: '53',
                            updateGameState: {
                                visited53: true
                            }
                        }
                    ]
                },

            ]
        },
        {
            id: '52',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783971746,
            goOnVideoEnd: [
                ['55', [{ visited52: true, visited53: true }]],
                '54'
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Dowiedz się więcej o pani Krysi.'
                }
            ]
        },
        {
            id: '53',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783971822,
            goOnVideoEnd: [
                ['55', [{ visited52: true, visited53: true }]],
                '54'
            ],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Posłuchaj historii tapeciarki.'
                }
            ]
        },
        {
            id: '54',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783973836,
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Porozmawiaj teraz o innym pracowniku.'
                },
                {
                    from: 6,
                    to: Infinity,
                    actions: [
                        {
                            text: 'Posłucham o kucharce.',
                            target: '52',
                            updateGameState: {
                                visited52: true
                            },
                            visible: [{ visited52: false }]
                        },
                        {
                            text: 'Zapytam o tapeciarkę.',
                            target: '53',
                            updateGameState: {
                                visited53: true
                            },
                            visible: [{ visited53: false }]
                        }
                    ]
                },

            ]
        },
        {
            id: '55',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783973913,
            events: [
                {
                    from: 3,
                    to: 10,
                    text: 'Dyrektor zamyśla się nad swoimi podwładnymi i ich losem. Obok dostrzegasz maszynę, o której powiedziano ci w stołówce. '
                },
                {
                    from: 10,
                    to: Infinity,
                    text: 'Czas wpisać szyfr składający się ze znaków, które widziałeś po drodze.',
                    actions: [
                        {
                            text: 'C1, Z8, D5',
                            onClickMessage: 'To nie ten zestaw liczb.'
                        },
                        {
                            text: 'Z5, H2, C1',
                            onClickMessage: 'To nie ten zestaw liczb.'
                        },
                        {
                            text: 'C5, C1, Z5',
                            target: '56'
                        }
                    ]
                }
            ]
        },
        {
            id: '56',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783971874,
            goOnVideoEnd: ['57'],
            events: [
                {
                    from: 3,
                    to: Infinity,
                    text: 'Brawo, wszystkie duchy zniknęły dzięki twojej pomocy! Teraz w końcu będzie można przeprowadzić remont budynku. Niedługo stanie się on siedzibą Żoliborskiego Domu Kultury, a duchy odpoczną w zaświatach.'
                }
            ]
        },
        {
            id: '57',
            type: GameStepType.video,
            source: 'vimeo',
            vimeoId: 783989154,
            goOnVideoEnd: ['end'],
            events: []
        },
        {
            id: 'end',
            type: GameStepType.custom,
            pageId: 'end-page'
        }
    ]
}

export default GameScenario;
