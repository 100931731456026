import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { getHomeRoute, getGameRoute } from './routes/routes';
import React, { Suspense } from 'react';
import Loader from './components/loader';

const HomePage = React.lazy(() => import('./routes/home'));
const GamePage = React.lazy(() => import('./routes/game'));

export default function App() {
    return (
        <BrowserRouter>
            <Suspense fallback={<Loader />}>
            <Routes>
                <Route path={getHomeRoute()} element={<HomePage />} />
                <Route path={getGameRoute()} element={<GamePage />} />
                <Route element={<HomePage />} />
            </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
