export enum GameStepType {
    video,
    custom
}

export enum VideoGameStepEventType {
}

export interface GameStep {
    id: string,
    type: GameStepType
}

export interface VideoGameStep extends GameStep {
    type: GameStepType.video
    events: VideoGameStepEvent[]
    source: 'local' | 'vimeo',
    url?: string
    vimeoId?: number

    /**
     * Algorytm sprawdza po kolei elementy tablicy.
     * - jak to jest string to przechodzi pod ten step
     * - jak to jest tablica to sprawedza czy wyrażenie na [1] jest poprawne i wtedy prezechodzi pod adres [0]
     */
    goOnVideoEnd?: conditionalValue<string>,
}

export interface VideoGameStepEvent {
    from: number,
    to: number,

    text?: string,
    actions?: VideoStepAction[],
    visible?: condition
}

export interface VideoStepAction {
    text: string,
    target?: conditionalValue<string>,
    onClickMessage?: string,
    updateGameState?: {[key: string]: boolean}
    visible?: condition
}

export interface CustomGameStep extends GameStep {
    type: GameStepType.custom
    pageId: string
}


export type GameData = {
    startStep: string,
    steps: (VideoGameStep | CustomGameStep)[]
}

export type condition = {[key: string]: boolean}[]

export type conditionalValue<T> = T | (T | [T, condition])[]
