import { GameModuleState, GameModuleAction, GAME_MODULE_ACTION, initGameModuleState } from ".";

export default function GameReducer(state: GameModuleState, action: GameModuleAction): GameModuleState {
    const { type, payload } = action;

    switch(type) {
        case GAME_MODULE_ACTION.GO_TO_STEP:
            return {
                ...state,
                activeStepId: payload.stepId
            }

        case GAME_MODULE_ACTION.RESTART:
            return {
                ...initGameModuleState
            }

        case GAME_MODULE_ACTION.UPDATE_GAME_STATE:
            return {
                ...state,
                state: {
                    ...state.state,
                    ...payload
                }
            }
    }

    return state;
}
